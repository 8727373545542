/*------------------------------------*\
RESET
\*------------------------------------*/
/* http://meyerweb.com/eric/tools/css/reset/ 
v2.0b1 | 201101 
NOTE:WORK IN PROGRESS
USE WITH CAUTION AND TEST WITH ABANDON */

html,body,div,span,applet,object,iframe,
h1,h2,h3,h4,h5,h6,p,blockquote,pre,
a,abbr,acronym,address,big,cite,code,
del,dfn,em,img,ins,kbd,q,s,samp,
small,strike,strong,sub,sup,tt,var,
b,u,i,center,
dl,dt,dd,ol,ul,li,
fieldset,form,label,legend,
table,caption,tbody,tfoot,thead,tr,th,td,
article,aside,canvas,details,figcaption,figure,
footer,header,hgroup,menu,nav,section,summary,
time,mark,audio,video{
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  font:inherit;
  vertical-align:baseline;
}
/* HTML5 display-role reset for older browsers */
article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section{
  display:block;
}
body{
  background-color: #ffd1d9;
  line-height:1;
}
ol,ul{
  list-style:none;
}
blockquote,q{
  quotes:none;
}
blockquote:before,blockquote:after,
q:before,q:after{
  content:'';
  content:none;
}
ins{
  text-decoration:none;
}
del{
  text-decoration:line-through;
}

/*-- Global ------------------------------------*/
* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  color: rgba(0, 0, 0, 0.8);
  font-family: 'Karla', sans-serif;
  -webkit-font-smoothing: antialiased;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

/*-- Atomic ------------------------------------*/
.flex-m {
  display: flex;
  flex-direction: column;
}

.bb {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.bc10 {
  border-color: rgba(0, 0, 0, 0.1);
}

.bc20 {
  border-color: rgba(0, 0, 0, 0.2);
}

.black60 {
  color: rgba(0, 0, 0, 0.75);
}

.fs14 {
  font-size: 1.4rem;
}

.fs16 {
  font-size: 1.6rem;
}

.fs24 {
  font-size: 2.4rem;
}

.fw500 {
  font-weight: 500;
}

.lh {
  line-height: 1.5;
}

.lh2 {
  line-height: 2.0;
}

.m0a {
  margin: 0 auto;
}

.mr1 {
  margin-right: 1rem;
}

.pb0_5 {
  padding-bottom: 0.5rem;
}

.pb1 {
  padding-bottom: 1rem;
}

.pt1 {
  padding-top: 1rem;
}

.pb2 {
  padding-bottom: 2rem;
}

.pb32 {
  padding-bottom: 3.2rem;
}

.pb4 {
  padding-bottom: 4rem;
}

.pt4 {
  padding-top: 4rem;
}

.w30 {
  width: 30%;
}

.w90 {
  width: 90%;
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .fs36-t {
    font-size: 3.6rem;
  }
}

@media screen and (min-width: 30em) {
  .fs20-td {
    font-size: 2rem;
  }
}

@media screen and (min-width: 60em) {
  .b-d {
    border: none;
  }
  
  .flex-d {
    flex-wrap: wrap;
    justify-content: space-between
  }
  
  .flex-row {
    flex-direction: row;
  }
  
  .fs36-d {
    font-size: 3.6rem;
  }
  
  .fs48-d {
    font-size: 4.8rem;
  }

  .mr2-d {
    margin-right: 2rem;
  }

  .w30-d {
    width: 30%;
  }

  .w80-d {
    width: 80%;
  }
}

/*-- Other ------------------------------------*/
.hibiscus {
  height: 5rem;
  width: 5rem;
}

.category {
  letter-spacing: .1em;
  text-transform: uppercase;
}

.hover:hover {
  color: #ff7b81;
}

.open-sans {
  font-family: 'Open Sans', sans-serif;
}
